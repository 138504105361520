
//https://docs.amplify.aws/lib/auth/start/q/platform/js#re-use-existing-authentication-resource
const awsconfig = {
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
    // REQUIRED - Amazon Cognito Region
    region: process.env.REACT_APP_REGION,

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: process.env.REACT_APP_USER_POOL_ID,

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,

    // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
    authenticationFlowType: 'USER_PASSWORD_AUTH',

    federationTarget: "COGNITO_USER_POOLS",
    // OPTIONAL - Configuration for cookie storage
    // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
    cookieStorage: {
    // REQUIRED - Cookie domain (only required if cookieStorage is provided)
        domain: process.env.REACT_APP_COOKIE_DOMAIN,
    // OPTIONAL - Cookie path
        path: '/',
    // OPTIONAL - Cookie expiration in days
        expires: 365,
    // OPTIONAL - Cookie secure flag
    // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
        secure: true
    },

    // OPTIONAL - customized storage object
    // storage: MyStorage,

    // OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
    // clientMetadata: {
    //   referrer: 'myCustomValue',
    //   utmParams: 'object'
    // },

    // OPTIONAL - Hosted UI configuration
    /*oauth: {
      domain: "dev-internal-users.auth.us-west-2.amazoncognito.com",
      scope: [
          "phone",
          "email",
          "openid",
          "profile",
          "aws.cognito.signin.user.admin"
      ],
      redirectSignIn: process.env.REDIRECT_SIGN_IN,
      redirectSignOut: process.env.REDIRECT_SIGN_OUT,
      responseType: "code"
    }*/
  }
};

export default awsconfig;
