import React from 'react';
import Amplify from 'aws-amplify';
import { AmplifyAuthenticator, AmplifySignOut } from '@aws-amplify/ui-react';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import './App.css';

// import awsconfig from './aws-exports';
// import awsconfig from './workplace-cognito-config';
import { awsConfig } from './config';
// import awsconfig from './internal-cognito-config';
//  https://github.com/aws-amplify/amplify-js/tree/master/packages/amazon-cognito-identity-js

Amplify.configure(awsConfig)
// https://aws-amplify.github.io/amplify-js/api/classes/authclass.html#configure

// function App() {
//   const [user, updateUser] = React.useState<any>(null);
//   React.useEffect(() => {
//     Auth.currentAuthenticatedUser()
//       .then(user => updateUser(user))
//       .catch(() => console.log('No signed in user.'));
//     Hub.listen('auth', data => {
//       console.log('event', data.payload.event);
//       switch (data.payload.event) {
//         case 'signIn':
//           console.log('Sign in user', data.payload.data);
//           return updateUser(data.payload.data);
//         case 'signOut':
//           console.log('signOut', data.payload.data);
//           return updateUser(null);
//       }
//     });
//
//   }, []);
//   if (user) {
//     return (
//       <div>
//         <h1>Hello {user?.username}</h1>
//         <AmplifySignOut />
//       </div>
//     )
//   }
//   /* Optionally, wrap the AmplifyAuthenticator in a div to control layout with CSS in JS */
//   return <AmplifyAuthenticator  />
// }

// export default App;

const AuthStateApp = () => {
  const [authState, setAuthState] = React.useState<any>();
  const [user, setUser] = React.useState<any>();

  React.useEffect(() => {
    return onAuthUIStateChange((nextAuthState, authData) => {if(authData) {
      const urlParams = new URLSearchParams(window.location.search);
      const redirectURI = urlParams.get('redirect_uri');
      if(redirectURI) {
        window.location.replace(redirectURI);
      } else {
        alert('No redirect');
      }
    }

      setAuthState(nextAuthState);
      setUser(authData)

    });
  }, []);

  return authState === AuthState.SignedIn && user ? (
      <div className="App">
        <div>Hello, {user.username}</div>
        <AmplifySignOut />
      </div>
  ) : (
      <AmplifyAuthenticator />
  );
}

export default AuthStateApp;
