
//https://docs.amplify.aws/lib/auth/start/q/platform/js#re-use-existing-authentication-resource
const awsconfig = {
  Auth: {
    identityPoolId: "us-west-2:f60602dd-97ff-411f-88a3-6623bc3ad7cb",
    // REQUIRED - Amazon Cognito Region
    region: 'us-west-2',

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: 'us-west-2_ElEoNh1Ox',

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: '7vo2mmvnb1karr5o3ug36vtgus',
    authenticationFlowType: 'USER_PASSWORD_AUTH',
    federationTarget: "COGNITO_USER_POOLS",
    // OPTIONAL - Configuration for cookie storage
    // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
    cookieStorage: {
    // REQUIRED - Cookie domain (only required if cookieStorage is provided)
    //     domain: '.dev.bluecrewenv.com', // TODO:
        domain: 'localhost',// TODO:
    // OPTIONAL - Cookie path
        path: '/',
    // OPTIONAL - Cookie expiration in days
        expires: 365,
    // OPTIONAL - Cookie secure flag
    // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
    //     secure: true // TODO:
        secure: false
    },

    // OPTIONAL - customized storage object
    // storage: MyStorage,

    // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
    //

    // OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
    clientMetadata: {
      referrer: 'myCustomValue',
      utmParams: 'object'
    },

    // OPTIONAL - Hosted UI configuration
    // oauth: {
    //   domain: "dev-workplace-users.auth.us-west-2.amazoncognito.com",
    //   scope: [
    //       "phone",
    //       "email",
    //       "openid",
    //       "profile",
    //       "aws.cognito.signin.user.admin"
    //   ],
    //   // redirectSignIn: "https://app.dev.bluecrewenv.com/",
    //   // redirectSignOut: "https://app.dev.bluecrewenv.com/",
    //   responseType: "code"
    // }
  }
};

export default awsconfig;
